import { useMatch } from "react-router-dom"
import { ViewOnlyContext } from "../context";
import EventGroupForm from "src/components/EventGroupForm";
import EventGroupHeader from "src/components/EventGroupHeader";
import ScrollableLayout from "src/layouts/ScrollableLayout";

interface Props {
    viewOnly?: boolean
}

export default function EventGroupScreen(props: Props) {
    const viewOnly = props.viewOnly || false;

    const routeMatch = useMatch('/group/:groupId');
    if (!routeMatch) {
        return <p>ERROR: unknown route</p>
    }

    const { groupId } = routeMatch.params;

    if (!groupId) {
        return <p>ERROR: group id</p>
    }

    return <ViewOnlyContext.Provider value={viewOnly}>
        <ScrollableLayout>
            <EventGroupHeader groupId={groupId} />
            <EventGroupForm groupId={groupId} />
        </ScrollableLayout>
    </ViewOnlyContext.Provider >
}