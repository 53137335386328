import { Fab } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { useContext } from "react";
import { WasteDataContext } from "src/context";
import { usePadding } from "src/spacing";

export const EDIT_DATA_BUTTON_HEIGHT = 56;

export default function EditDataButton() {
    const { editMode, toggleEditMode } = useContext(WasteDataContext);
    const { bottomPadding } = usePadding();

    return <Fab
        color="primary"
        onClick={toggleEditMode}
        sx={{
            height: EDIT_DATA_BUTTON_HEIGHT,
            width: EDIT_DATA_BUTTON_HEIGHT,
            position: 'fixed',
            bottom: bottomPadding + 16,
            right: 16,
            zIndex: 10,
        }}
    >
        {editMode ? <ViewIcon /> : <EditIcon />}
    </Fab>
}