import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { db } from '../instant';
import { id, TransactionChunk } from '@instantdb/core';
import { Button, Paper, Stack, Typography, Box, ToggleButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { createMaps, newEmptyMap } from '../upload';
import AddNewMaps from './AddNewMaps';
import { useEvent } from '../context';
import MapList from './MapList';
import FormTextInput from './FormTextInput';
import { AppSchema } from '@zw-app/shared/instant.schema';
import DiscardChangesDialog from './DiscardChangesDialog';
import DirtyChecker from './DirtyChecker';
import { CustomMaterialSchema, eventFormSchema, EventFormValues } from 'src/schema';
import DatasetList from './DatasetList';
import CustomMaterialList from './CustomMaterialList';
import { getOneLink } from 'src/utils';

interface Props {
    eventId?: string;
    viewOnly?: boolean;
}

export default function EventForm(props: Props) {
    const { eventId, viewOnly = false } = props;
    const navigate = useNavigate();
    const { user, isLoading, error } = db.useAuth();
    const [isUploading, setIsUploading] = useState(false);
    const [editMode, setEditMode] = useState(!eventId);
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
    const isCreating = (eventId === undefined);
    const event = useEvent(eventId);
    const existingMaps = event?.maps || [];
    const numOtherMaps = event ? existingMaps.length : 0;

    const targetEventId = eventId || id();

    const [hasBeenEdited, setHasBeenEdited] = useState(false);

    const discard = useCallback(() => {
        setEditMode(false);
        setHasBeenEdited(false);
    }, [setEditMode, setHasBeenEdited]);

    const closeDiscardDialog = useCallback(() => {
        setDiscardDialogOpen(false);
    }, [setDiscardDialogOpen]);

    const toggleEditMode = useCallback(() => {
        if (editMode && hasBeenEdited) {
            setDiscardDialogOpen(true);
        } else {
            setEditMode(!editMode)
        }
    }, [editMode, hasBeenEdited, setEditMode, setDiscardDialogOpen]);

    if (isLoading) return <div>Authenticating...</div>;
    if (error) return <div>Error authenticating: {error.message}</div>;

    const customMaterials: CustomMaterialSchema[] = event?.customMaterials
        .map(m => {
            const category = getOneLink(m.category);
            return category ? {
                id: m.id,
                custom: m.custom,
                name: m.name,
                order: m.order,
                categoryId: category.id,
            } : undefined;
        })
        .filter(m => m !== undefined) || [];

    const initialValues: EventFormValues = event ? {
        eventName: event.name,
        existingMaps: event.maps,
        datasets: event.datasets,
        customMaterials,
        newMaps: [],
    } : {
        eventName: '',
        existingMaps: [],
        datasets: [{ order: 0, name: "" }],
        customMaterials,
        newMaps: [newEmptyMap()],
    };

    const maybeUploadSpinner = isUploading ? <LoadingIndicator message={eventId ? "Updating event..." : "Creating event..."} /> : null;
    const canEdit = !isCreating && !viewOnly && user;

    const subtitle = isCreating ? "Create Event" : editMode ? "Edit Event" : "Event Info";

    return <>
        <Paper sx={{ position: "relative", minWidth: "300px", width: "100%", maxWidth: "400px", margin: "20px", padding: 2 }} elevation={4}>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                    {subtitle}
                </Typography>
                {canEdit && <ToggleButton
                    value="edit-mode"
                    selected={editMode}
                    onClick={toggleEditMode}
                >
                    <EditIcon />
                </ToggleButton>}
            </Box>
            <Formik
                initialValues={initialValues}
                // If events change in DB, update the form values
                // e.g. when deleting maps, or after saving changes
                enableReinitialize
                validationSchema={eventFormSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    const token = user?.refresh_token;
                    if (!token) throw new Error('no token!');

                    const otherTxns: TransactionChunk<AppSchema, any>[] = [
                        db.tx.events[targetEventId].update({
                            name: values.eventName
                        })
                    ];

                    values.datasets.forEach(dataset => {
                        otherTxns.push(
                            db.tx.wasteDatasets[dataset.id || id()]
                                .update({
                                    name: dataset.name,
                                    order: dataset.order,
                                })
                                .link({
                                    event: targetEventId
                                })
                        )
                    });

                    values.customMaterials.forEach(material => {
                        otherTxns.push(
                            db.tx.wasteMaterials[material.id || id()]
                                .update({
                                    name: material.name,
                                    order: material.order,
                                    custom: true,
                                })
                                .link({
                                    forEvent: targetEventId,
                                    category: material.categoryId,
                                })
                        )
                    });

                    values.existingMaps.forEach(map => {
                        if (map.id) otherTxns.push(db.tx.maps[map.id].update({ name: map.name }));
                    });

                    const params = {
                        newMaps: values.newMaps,
                        eventId: targetEventId,
                        orderOffset: eventId ? numOtherMaps : 0,
                        otherTxns,
                        token,
                        setIsUploading,
                        setSubmitting,
                    };
                    await createMaps(params);

                    if (isCreating) {
                        navigate(`/event/${targetEventId}`)
                    } else {
                        setEditMode(false);
                    }
                }}
            >
                {({ values, isSubmitting }) => <>
                    <DiscardChangesDialog
                        discard={discard}
                        open={discardDialogOpen}
                        onClose={closeDiscardDialog}
                    />
                    <DirtyChecker dirty={hasBeenEdited} setDirty={setHasBeenEdited} />
                    <Form>
                        <Stack direction="column" spacing={1} sx={{ paddingTop: "10px" }}>
                            {editMode && (
                                <Field
                                    autoFocus={!eventId}
                                    required
                                    name="eventName"
                                    component={FormTextInput}
                                    label="Event name"
                                    fullWidth
                                />
                            )}

                            <br />
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                Maps
                            </Typography>
                            <MapList
                                eventName={event?.name || values.eventName}
                                existingMaps={values.existingMaps}
                                editMode={editMode}
                            />

                            {editMode && <>
                                <AddNewMaps
                                    newMaps={values.newMaps}
                                    isSubmitting={isSubmitting}
                                    numOtherMaps={numOtherMaps}
                                />
                            </>}

                            <br />
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                Waste Datasets
                            </Typography>
                            <DatasetList
                                eventName={event?.name || values.eventName}
                                datasets={values.datasets}
                                isSubmitting={isSubmitting}
                                editMode={editMode}
                            />

                            <br />
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                Custom Materials
                            </Typography>
                            <CustomMaterialList
                                eventName={event?.name || values.eventName}
                                customMaterials={values.customMaterials}
                                isSubmitting={isSubmitting}
                                editMode={editMode}
                            />

                            {editMode && <>
                                <br />
                                <Stack direction="row" sx={{ justifyContent: "center" }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        {isCreating ? 'Create Event' : 'Save All Changes'}
                                    </Button>
                                </Stack>
                            </>}
                        </Stack>
                    </Form>
                </>}
            </Formik>
            {maybeUploadSpinner}
        </Paper>
    </>;
}