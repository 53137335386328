import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { FieldProps } from "formik";
import { useEventsList, useEventsMap } from "src/context";

export default function EventAutocomplete(props: FieldProps) {
    const { form, field, ...otherProps } = props;
    const allEvents = useEventsList();
    const allEventIds = allEvents.map(event => event.id);
    const eventsMap = useEventsMap();

    // TODO: This appears too much
    const FALLBACK = '<ERROR FETCHING EVENT>';

    const getNestedValue = (obj: any, path: string) => {
        return path.split(/[.[\]]+/).filter(Boolean).reduce((acc, part) =>
            acc ? acc[part] : undefined, obj
        );
    };

    return <Autocomplete<string>
        options={allEventIds}
        getOptionLabel={(eventId: string) => eventsMap.get(eventId)?.name || FALLBACK}
        getOptionKey={(eventId: string) => eventId}
        filterOptions={(options, { inputValue, getOptionLabel }) => {
            return options.filter(option => {
                const eventName = getOptionLabel(option);
                const matchesInput = eventName.toLowerCase().includes(inputValue.toLowerCase());
                const notAlreadySelected = !form.values.eventIds.includes(option);
                return matchesInput && notAlreadySelected;
            });
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
                name={field.name}
                label="Select event"
                error={getNestedValue(form.touched, field.name) && Boolean(getNestedValue(form.errors, field.name))}
                helperText={(getNestedValue(form.touched, field.name) && getNestedValue(form.errors, field.name)) as string}
                {...params}
            />
        )}
        onChange={(ev: React.ChangeEvent<any>, newValue: string | null) => {
            form.setFieldTouched(field.name).catch(console.error);
            form.setFieldValue(field.name, newValue).catch(console.error);
        }}
        onBlur={field.onBlur}
        value={field.value}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        sx={{ flexGrow: 1 }}
        {...otherProps}
    />
}