import { Stack, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import Dropdown, { DropdownOption } from "./Dropdown";
import { db } from "src/instant";
import { DataInputValues, MeasurementUnit } from "src/schema";
import { AppSchema } from "@zw-app/shared/instant.schema";
import { InstaQLEntity, InstaQLResult } from "@instantdb/react";
import { useEffect, useMemo } from "react";
import MaterialDropdown, { NEW_CUSTOM_OPTION } from "./MaterialDropdown";

interface Props {
    eventId: string,
    unitOptions: DropdownOption<MeasurementUnit>[];
    setNumCustomMaterials: (n: number) => void;
}

type WasteCategoryWithMaterials = InstaQLResult<AppSchema, { wasteCategories: { materials: {} } }>['wasteCategories'][number];
type WasteMaterial = InstaQLEntity<AppSchema, 'wasteMaterials'>

export default function DataInputDropdowns(props: Props) {
    const { setNumCustomMaterials, unitOptions } = props;
    const { data, isLoading, error } = db.useQuery({
        wasteCategories: {
            materials: {
                $: {
                    order: { order: 'asc' },
                    where: {
                        or: [
                            { 'forEvent.id': props.eventId },
                            { custom: false },
                        ],
                    },
                },
            },
            $: { order: { order: 'asc' } }
        },
    });

    const { values, setFieldValue, isSubmitting } = useFormikContext<DataInputValues>();
    const { categoryId, materialId } = values;

    const {
        categoryOptions,
        standardMaterialOptions,
        customMaterialOptions,
        disableCategoryDropdown,
        disableMaterialDropdown,
    } = useMemo(() => {
        const wasteCategories = data?.wasteCategories ?? [];

        const categoryOptions: DropdownOption<string>[] = wasteCategories.map(
            category => ({
                label: category.name,
                value: category.id,
            })
        );

        const categoryMap = new Map<string, WasteCategoryWithMaterials>();
        for (let category of wasteCategories) {
            categoryMap.set(category.id, category)
        }

        const currentCategory = categoryId ? categoryMap.get(categoryId) : undefined;

        const materialMap = new Map<string, WasteMaterial>();
        let standardMaterialOptions: DropdownOption<string>[] = [];
        let customMaterialOptions: DropdownOption<string>[] = [];

        if (currentCategory !== undefined) {
            for (let material of currentCategory.materials) {
                materialMap.set(material.id, material);
            }

            standardMaterialOptions = currentCategory.materials
                .filter(material => !material.custom)
                .map(
                    material => ({
                        label: material.name,
                        value: material.id,
                    })
                );

            customMaterialOptions = currentCategory.materials
                .filter(material => material.custom)
                .map(
                    material => ({
                        label: material.name,
                        value: material.id,
                    })
                );
        }

        let disableCategoryDropdown = false;
        let disableMaterialDropdown = false;

        if (isLoading || isSubmitting) {
            disableCategoryDropdown = true;
            disableMaterialDropdown = true;
        }

        if (categoryOptions.length === 0) {
            disableCategoryDropdown = true;
        }
        if (standardMaterialOptions.length + customMaterialOptions.length === 0) {
            disableMaterialDropdown = true;
        }

        return {
            categoryOptions,
            standardMaterialOptions,
            customMaterialOptions,
            disableCategoryDropdown,
            disableMaterialDropdown,
        };
    }, [
        data,
        isLoading,
        isSubmitting,
        categoryId,
    ]);

    const numCustomMaterials = customMaterialOptions.length;
    useEffect(() => {
        setNumCustomMaterials(numCustomMaterials);
    }, [numCustomMaterials, setNumCustomMaterials]);

    // Make sure category is valid
    useEffect(() => {
        const categoryOptionIds = categoryOptions.map(opt => opt.value);

        console.log("CATEGORY EFFECT");
        if (!categoryOptionIds.includes(categoryId)) {
            if (categoryOptions.length > 0) {
                setFieldValue('categoryId', categoryOptions[0].value)
                    .catch(console.error);
            }
        }
    }, [categoryId, categoryOptions, setFieldValue]);

    // Make sure material is valid
    // TODO: this doesn't prevent invalid value warnings
    // when category is changed
    useEffect(() => {
        const materialOptions = [
            ...standardMaterialOptions,
            ...customMaterialOptions,
            NEW_CUSTOM_OPTION,
        ];
        const materialOptionIds = materialOptions.map(opt => opt.value);

        console.log("MATERIAL EFFECT");
        if (!materialOptionIds.includes(materialId)) {
            if (materialOptions.length > 1) {
                setFieldValue('materialId', materialOptions[0].value)
                    .catch(console.error);
            }
        }
    }, [materialId, standardMaterialOptions, customMaterialOptions, setFieldValue]);

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    return <Stack direction="row">
        <Field
            name="categoryId"
            component={Dropdown}
            options={categoryOptions}
            // value={currentCategoryId || ""}
            label="Category"
            // handleChange={updateCategoryFromDropdown}
            disabled={disableCategoryDropdown}
            sx={{ width: "120px" }}
        />
        <Field
            name="materialId"
            component={MaterialDropdown}
            standardOptions={standardMaterialOptions}
            customOptions={customMaterialOptions}
            // value={currentMaterialId || ""}
            label="Material"
            // handleChange={updateMaterialFromDropdown}
            disabled={disableMaterialDropdown}
            sx={{ width: "130px " }}
        />
        <Field
            name="units"
            component={Dropdown}
            options={unitOptions}
            // value={currentUnits || ""}
            label="Units"
            sx={{ width: "80px" }}
        // handleChange={updateUnitsFromDropdown}
        />
    </Stack>
}