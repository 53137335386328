import L from "leaflet";
import { MapPin, StationType } from "../schema"
import { Marker } from "react-leaflet";
import { getStationIconSrc } from "../utils";
import StationMarkerPopup from "./StationMarkerPopup";
import { useContext } from "react";
import { MapContext } from "src/context";
import { db } from "src/instant";

interface Props {
    pin: MapPin
    station: StationType
    position: L.LatLng
    onDelete: () => void
}

export default function StationMarker(props: Props) {
    const {cleanupMode} = useContext(MapContext);
    const pinId = props.pin.id;
    const cleanupMarker = async () => {
        db.transact(
            db.tx.mapPins[pinId].update({
                cleanedUp: true
            })
        );
    }
    const iconSrc = getStationIconSrc(props.station);
    const leafletIcon = L.icon({ iconUrl: iconSrc, iconSize: [50, 50] });

    const handleMarkerClick = (event: L.LeafletMouseEvent) => {
        if (cleanupMode) {
            console.log('cleanup mode');
            cleanupMarker();
        }
    };

    return <Marker icon={leafletIcon} position={props.position} eventHandlers={{ click: handleMarkerClick }}>
        {!cleanupMode && <StationMarkerPopup
            pin={props.pin}
            station={props.station}
            iconSrc={iconSrc}
            onDelete={props.onDelete}
        />}
    </Marker>
}