import { FieldArray } from "formik";
import { DatasetSchema } from "../schema";
import DatasetListSection from "./DatasetListSection";
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from "@mui/material";
import { useCallback } from "react";

interface Props {
    eventName: string;
    datasets: DatasetSchema[];
    isSubmitting: boolean;
    editMode: boolean;
}

export default function DatasetList(props: Props) {
    const { eventName, datasets, isSubmitting, editMode } = props;

    const numDatasets = datasets.length;
    const newDataset = useCallback(() => {
        return {
            order: numDatasets,
            name: "",
        };
    }, [numDatasets]);

    return <FieldArray name="datasets">
        {({ push, remove }) => (
            <>
                {datasets.map((dataset, index) => (
                    <DatasetListSection
                        key={index}
                        index={index}
                        eventName={eventName}
                        dataset={dataset}
                        // Don't let the last dataset be deleted
                        deleteable={datasets.length > 1}
                        editMode={editMode}
                        remove={remove}
                    />
                ))}

                {editMode && <Stack direction="row-reverse" key="stack">
                    <Button
                        color="success"
                        disabled={isSubmitting}
                        onClick={() => {
                            push(newDataset());
                        }}
                        startIcon={<AddIcon />}
                    >
                        Add dataset
                    </Button>
                </Stack>}
            </>
        )}
    </FieldArray>
}