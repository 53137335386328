import { id } from "@instantdb/react";
import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import { db } from "src/instant";
import { DropdownOption } from "./Dropdown";
import { Field, Form, Formik, useFormikContext } from "formik";
import FormTextInput from "./FormTextInput";
import { dataInputValidationSchema, DataInputValues, MeasurementUnit } from "src/schema";
import DataInputDropdowns from "./DataInputDropdowns";
import { useFixedElement } from "src/spacing";
import { EVENT_BOTTOM_NAV_HEIGHT } from "./EventBottomNavigation";
import { getOneLink, useMobileKeyboardOpen } from "src/utils";
import { useCallback, useContext, useEffect, useState } from "react";
import { WasteDataContext } from "src/context";
import { NEW_CUSTOM_OPTION } from "./MaterialDropdown";

interface Props {
    eventId: string;
}

interface InnerProps {
    eventId: string;
    setIsNewCustom: (x: boolean) => void
    setNumCustomMaterials: (n: number) => void
}

const unitOptions: DropdownOption<MeasurementUnit>[] = [
    { value: 'lb', label: 'lb' },
    { value: 'yd3', label: 'yd^3' },
    { value: 'gal', label: 'gal' },
];
const defaultUnits = unitOptions[0].value;

function InnerForm(props: InnerProps) {
    const { selectedRow } = useContext(WasteDataContext);
    const {
        isSubmitting,
        isValid,
        values,
        handleSubmit,
        setValues,
        setFieldValue,
        setFieldTouched
    } = useFormikContext<DataInputValues>();
    const { setIsNewCustom } = props;

    const isEditing = (selectedRow !== null);

    // Update form values when a new row is selected
    useEffect(() => {
        if (isEditing) {
            const oneMaterial = getOneLink(selectedRow.material);
            const oneCategory = getOneLink(oneMaterial?.category)
            setValues({
                categoryId: oneCategory?.id as string,
                materialId: oneMaterial?.id as string,
                units: selectedRow.units,
                quantity: selectedRow.quantity,
            });
        } else {
            // Reset quantity field when row is de-selected.
            (async () => {
                await setFieldValue('quantity', '');
                await setFieldTouched('quantity', false);
            })().catch(console.error);
        }
    }, [
        isEditing,
        selectedRow,
        setValues,
        setFieldValue,
        setFieldTouched
    ]);

    const editingText = isEditing ? <Typography
        sx={{ color: "gray", marginBottom: "5px" }}
    >
        Editing previous entry
    </Typography> : <></>;

    const isNewCustom = (values.materialId === NEW_CUSTOM_OPTION.value);

    useEffect(() => {
        setIsNewCustom(isNewCustom);
    }, [isNewCustom, setIsNewCustom]);

    return <Form autoComplete="off" onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit();
    }}>
        <Stack direction="column" sx={{ alignItems: "center" }}>
            {editingText}
            <DataInputDropdowns
                eventId={props.eventId}
                unitOptions={unitOptions}
                setNumCustomMaterials={props.setNumCustomMaterials}
            />
            <Stack direction="row" sx={{ width: "100%", justifyItems: "left" }}>
                {isNewCustom && <Field
                    sx={{ margin: "5px" }}
                    autoFocus={true}
                    required={true}
                    autoComplete="off"
                    name="customMaterialName"
                    component={FormTextInput}
                    label="Custom Material"
                    aria-autocomplete="none"
                />}
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
                <Stack direction="column" sx={{ margin: "5px" }}>
                    <Field
                        autoFocus={true}
                        required={true}
                        autoComplete="off"
                        name="quantity"
                        component={FormTextInput}
                        label="Quantity"
                        aria-autocomplete="none"
                        inputProps={{
                            inputMode: "decimal",
                        }}
                    />
                    {/* TODO: TIMESTAMP */}
                </Stack>
                <Button
                    sx={{
                        height: "56px",
                        margin: "5px",
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                >
                    Submit
                </Button>
            </Stack>
        </Stack>
    </Form>;
}

export default function DataInputForm(props: Props) {
    const initialValues = {
        categoryId: "",
        materialId: "",
        customMaterialName: "",
        units: defaultUnits,
        quantity: "",
    };
    type FormValues = typeof initialValues;

    const { eventId } = props;
    const [numCustomMaterials, setNumCustomMaterials] = useState(0);
    const { datasetId, selectedRow, setSelectedRow } = useContext(WasteDataContext);
    const [isNewCustom, setIsNewCustom] = useState(false);

    const ref = useFixedElement("bottom");
    const isKeyboardOpen = useMobileKeyboardOpen();
    const bottom = isKeyboardOpen ? 0 : EVENT_BOTTOM_NAV_HEIGHT;

    const isEditing = (selectedRow !== null);

    let formHeight = 165;

    if (isEditing) {
        formHeight += 10;
    }

    if (isNewCustom) {
        formHeight += 66;
    }

    const createNewCustomMaterial = useCallback(async (
        values: FormValues,
        setFieldValue: (field: string, value: any) => void,
    ): Promise<string> => {
        const newMaterialId = id();
        console.log('NEW CUSTOM MATERIAL', values, eventId, newMaterialId);
        await db.transact(db.tx.wasteMaterials[newMaterialId]
            .update({
                name: values.customMaterialName,
                order: numCustomMaterials,
                custom: true,
            })
            .link({
                forEvent: eventId,
                category: values.categoryId,
            })
        );
        // Select the new material
        setFieldValue('materialId', newMaterialId);

        return newMaterialId;

    }, [numCustomMaterials, eventId]);


    const createNewMeasurement = useCallback(async (values: FormValues, materialId: string) => {
        const measurementId = id();
        if (!datasetId) {
            console.error('ATTEMPTED TO CREATE MEASUREMENT WITHOUT DATASET ID');
            return;
        }

        await db.transact(db.tx.wasteMeasurements[measurementId].update({
            quantity: parseFloat(values.quantity),
            timestamp: Date.now(),
            units: values.units,
        }).link({
            dataset: datasetId,
            material: materialId,
        }))
    }, [datasetId]);

    const updateMeasurement = useCallback(async (measurementId: string, values: FormValues, materialId: string) => {
        await db.transact(db.tx.wasteMeasurements[measurementId].update({
            quantity: parseFloat(values.quantity),
            // timestamp: Date.now(),
            units: values.units,
        }).link({
            material: materialId,
        }))
    }, []);

    return <Paper
        id="data-input-form-box"
        ref={ref}
        elevation={5}
        sx={{
            // position: "fixed",
            // bottom: keyboardHeight > 0 ? `${keyboardHeight}px` : `${EVENT_BOTTOM_NAV_HEIGHT}px`,
            // bottom: `${EVENT_BOTTOM_NAV_HEIGHT}px`,
            position: "fixed",
            bottom,
            // margin: "auto",
            width: "100%",
            // height: EVENT_DATA_INPUT_FORM_HEIGHT,
            height: formHeight,
            // minHeight: 165,
            background: "white",
            transition: "bottom 0.3s ease-in-out",
            overflow: "hidden",
            zIndex: 9,
            padding: "10px",
        }}
    >
        <Container
            maxWidth="sm"
            sx={{ justifyItems: "center" }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={dataInputValidationSchema}
                onSubmit={async (values, { setSubmitting, setFieldValue, setFieldTouched }) => {
                    setSubmitting(true);
                    let materialId = values.materialId;
                    if (isNewCustom) {
                        materialId = await createNewCustomMaterial(values, setFieldValue);
                        await setFieldValue('customMaterialName', '');
                        await setFieldTouched('customMaterialName', false);
                    }
                    if (selectedRow !== null) {
                        await updateMeasurement(
                            selectedRow.id,
                            values,
                            materialId,
                        );
                        setSelectedRow(null);
                    } else {
                        await createNewMeasurement(values, materialId);
                    }
                    await setFieldValue('quantity', '');
                    await setFieldTouched('quantity', false);
                    setSubmitting(false);
                }}
            >
                <InnerForm
                    setIsNewCustom={setIsNewCustom}
                    setNumCustomMaterials={setNumCustomMaterials}
                    eventId={props.eventId}
                />
            </Formik>
        </Container>
    </Paper>
}