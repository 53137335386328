import { init } from "@instantdb/react";
import schema from '@zw-app/shared/instant.schema';

// Visit https://instantdb.com/dash to get your APP_ID :)
// note: this is not a secret since it's meant to be exposed to the client.
export const INSTANTDB_APP_ID = process.env.REACT_APP_INSTANTDB_APP_ID;

if (!INSTANTDB_APP_ID) {
    throw new Error("INSTANTDB_APP_ID is not set! A value must be provided.");
}

// This will be initialized once and shared among components that import it.
export const db = init({ appId: INSTANTDB_APP_ID, schema });