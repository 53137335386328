import { Box, Stack, Typography } from "@mui/material";
import { useEventGroup } from "src/context";

interface Props {
    groupId: string
}

export default function EventGroupHeader(props: Props) {
    const group = useEventGroup(props.groupId);
    return <Stack direction="row">
        <Box sx={{ width: "100%", overflowX: "auto", margin: "5px", padding: "5px" }} >
            <Typography variant="h3" sx={{ textAlign: "center", whiteSpace: "nowrap", margin: "auto" }}>{group?.name || "NONE"}</Typography>
        </Box>
    </Stack>
}