import { useFormikContext } from "formik";
import { useEffect } from "react";

interface Props {
    dirty: boolean;
    setDirty: (dirty: boolean) => void;
}

export default function DirtyChecker(props: Props) {
    const { dirty: formikDirty } = useFormikContext();
    const { dirty: propDirty, setDirty } = props;

    useEffect(() => {
        if (formikDirty && !propDirty) {
            setDirty(true);
        }
    }, [formikDirty, propDirty, setDirty]);

    return null;
};