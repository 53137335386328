import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useMobileKeyboardOpen } from "src/utils";
import { Link } from "react-router-dom";
import { useFixedElement } from "src/spacing";
import { eventTabsConfig } from "src/routes";

export const EVENT_BOTTOM_NAV_HEIGHT = 56;

interface Props {
    activeTabId: number
    routePrefix: string
    eventId: string
}

export default function EventBottomNavigation(props: Props) {
    const { activeTabId, routePrefix, eventId } = props;
    const ref = useFixedElement("bottom");
    const mobileKeyboardOpen = useMobileKeyboardOpen();

    if (mobileKeyboardOpen) {
        return <></>
    }

    return <Paper
        ref={ref}
        elevation={3}
        style={{
            position: "fixed",
            bottom: 0,
            width: "100vw",
            height: EVENT_BOTTOM_NAV_HEIGHT,
            zIndex: 10
        }}

    >
        <BottomNavigation showLabels value={activeTabId}>
            {eventTabsConfig.map((tab, index) => (
                <BottomNavigationAction
                    key={tab.id}
                    label={tab.label}
                    icon={<tab.icon />}
                    component={Link}
                    to={`${routePrefix}/${eventId}/${tab.id}`}
                    value={index}
                />
            ))}
        </BottomNavigation>
    </Paper>;
}