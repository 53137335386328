import { RouterProvider} from "react-router-dom"
import { IMAGE_UPLPOAD_RETRY_EVENT_TYPE } from "./constants";
import { useEffect } from "react";
import { Workbox } from 'workbox-window';
import { router } from "./routes";

export default function App() {
  useEffect(() => {
    // Retry image uploads when back online
    // see https://stackoverflow.com/a/55338293/4228052
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js')

      window.addEventListener('online', function (event) {
        console.log('back online!');
        wb.messageSW({ type: IMAGE_UPLPOAD_RETRY_EVENT_TYPE });
      });
    }

  }, [])
  return <RouterProvider router={router} />;
}
