import { useEffect, useRef, useState } from "react";
import { StationType } from "./schema";

export const getStationIconSrc = (station: StationType): string => {
    const pathname = `/icons/${station.iconName}`;

    const base = "http://example.com"; // Not actually used
    const url = new URL(pathname, base);

    // Bump version number to update icons (bust cache)
    // This is not the most elegant way to do this.
    const iconVersion = 2;
    url.searchParams.set('v', iconVersion.toString());

    // Combine everything after the domain name
    const relativeUrl = url.pathname + url.search;
    return relativeUrl;
}

export const sha256Text = async (s: string): Promise<string> => {
    const data = new TextEncoder().encode(s);
    return await sha256Array(data);
}

// work around InstantDB bug - sometimes a "one" link
// returns a single-element array, but sometimes it
// returns the raw element.
// bug report: https://discord.com/channels/1031957483243188235/1332124262139564052/1339340495654228101
export const getOneLink = <T,>(_x: T | T[] | undefined): T | undefined => {
    const x = _x as T | T[] | undefined;
    if (Array.isArray(x)) {
        if (x.length > 0) {
            return x[0];
        } else {
            return undefined;
        }
    } else {
        return x;
    }
}

export const sha256Array = async (arr: BufferSource): Promise<string> => {
    const hashBuffer = await crypto.subtle.digest("SHA-256", arr);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

    return hashHex;
}

export const getFileExtension = (fileName: string) => fileName.split(".").pop();

export const fetchImage = async (imageUrl: string): Promise<string> => {
    const resp = await fetch(
        imageUrl,
    );
    const imgBlob = await resp.blob();
    // Return image as data url string
    const objectUrl = URL.createObjectURL(imgBlob);
    return objectUrl
};

export const timeout = (ms: number): Promise<void> => {
    return new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    })
}

const MIN_KEYBOARD_HEIGHT = 300;
const DEFAULT_KEYBOARD_OPEN = false;
export const useMobileKeyboardOpen = () => {
    const [isOpen, setIsOpen] = useState(DEFAULT_KEYBOARD_OPEN);
    const initialViewportHeight = useRef(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            const currentHeight = window.innerHeight;
            const heightDifference = initialViewportHeight.current - currentHeight;

            setIsOpen(heightDifference > MIN_KEYBOARD_HEIGHT);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [initialViewportHeight]);

    return isOpen;
};

export const useMobileKeyboardOpenOld = () => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.visualViewport) {
                console.log('VISUAL RESIZE');
                console.log('visual', window.visualViewport.height)
                console.log('inner', window.innerHeight)
                setIsKeyboardOpen(window.visualViewport.height < window.innerHeight);
            }
        };

        if (window.visualViewport) {
            window.visualViewport.addEventListener("resize", handleResize);
        }

        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener("resize", handleResize);
            }
        };
    }, []);

    console.log('keyboard open?', isKeyboardOpen);

    return isKeyboardOpen;
};