import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DatasetSchema } from "../schema";
import { db } from "../instant";

interface Props {
    eventName: string,
    dataset?: DatasetSchema,
    numMeasurements: number | undefined,
    open: boolean,
    onClose: () => void,
    removeField: () => void,
}

export default function DeleteDatasetDialog(props: Props) {
    const { user } = db.useAuth();

    if (props.dataset?.id === undefined) {
        return <></>
    }


    const deleteAndClose = async () => {
        if (props.dataset) {
            if (props.dataset.id) {
                await db.transact(
                    db.tx.wasteDatasets[props.dataset.id].delete(),
                );
            }

            props.removeField();
            props.onClose();
        }
    }

    const eventName = props.eventName || "<UNDEFINED>"
    const datasetName = props.dataset?.name || "<UNDEFINED>";

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really delete this dataset?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The dataset "{datasetName}" of event "{eventName}" (and {props.numMeasurements} measurements) will be deleted.
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button disabled={!user} onClick={deleteAndClose} color="error">Delete</Button>
        </DialogActions>
    </Dialog>
}