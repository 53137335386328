import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CREATE_EVENT_ROUTE, CREATE_EVENT_GROUP_ROUTE } from "../constants";
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';

export default function CreateFab() {
    const navigate = useNavigate();

    const actions = [
        { 
            icon: <EventIcon />, 
            name: 'Create Event',
            onClick: () => navigate(CREATE_EVENT_ROUTE)
        },
        { 
            icon: <FolderIcon />, 
            name: 'Create Group',
            onClick: () => navigate(CREATE_EVENT_GROUP_ROUTE)
        },
    ];

    return (
        <SpeedDial
            ariaLabel="Create menu"
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 32,
            }}
            icon={<SpeedDialIcon />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>
    );
} 