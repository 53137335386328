import { IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import DeleteEventDialog from "./DeleteEventDialog";
import DeleteEventGroupDialog from "./DeleteEventGroupDialog";
import { Event, EventGroup } from "../schema";
import { useEventsList, useEventGroupList } from "../context";
import { getOneLink } from "src/utils";

export default function EventsList() {
    const [deleteEventDialogOpen, setDeleteEventDialogOpen] = useState(false);
    const [deleteEventGroupDialogOpen, setDeleteEventGroupDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<Event | undefined>(undefined);
    const [eventGroupToDelete, setEventGroupToDelete] = useState<EventGroup | undefined>(undefined);
    const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
    const navigate = useNavigate();
    const events = useEventsList();
    const eventGroups = useEventGroupList();
    const ungroupedEvents = events.filter(event => !getOneLink(event.group));

    const maxOrder = events.reduce((max, event) => Math.max(max, event.order), 0) || 0;

    const handleCloseDeleteEventDialog = () => {
        setDeleteEventDialogOpen(false);
    };

    const handleCloseDeleteEventGroupDialog = () => {
        setDeleteEventGroupDialogOpen(false);
    };

    const handleClickDeleteEvent = (event: Event) => {
        setEventToDelete(event);
        setDeleteEventDialogOpen(true);
    };

    const handleClickDeleteGroup = (eventGroup: EventGroup) => {
        setEventGroupToDelete(eventGroup);
        setDeleteEventGroupDialogOpen(true);
    };

    const toggleGroup = (groupId: string) => {
        setOpenGroups(prev => ({
            ...prev,
            [groupId]: !prev[groupId]
        }));
    };

    return <>
        <DeleteEventDialog
            event={eventToDelete}
            open={deleteEventDialogOpen}
            onClose={handleCloseDeleteEventDialog}
        />
        <DeleteEventGroupDialog
            eventGroup={eventGroupToDelete}
            maxOrder={maxOrder}
            open={deleteEventGroupDialogOpen}
            onClose={handleCloseDeleteEventGroupDialog}
        />
        <Paper sx={{ minWidth: "300px", width: "100%", maxWidth: "400px", margin: "20px" }} elevation={4}>
            <Typography variant="h2" sx={{ textAlign: "center" }}>Events</Typography>

            <List style={styles.eventsList}>
                {eventGroups.map((group) => (
                    <div key={group.id}>
                        <ListItem
                            disablePadding
                            secondaryAction={
                                <IconButton
                                    onClick={() => handleClickDeleteGroup(group)}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <IconButton
                                onClick={() => toggleGroup(group.id)}
                                size="small"
                            >
                                {openGroups[group.id] ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <ListItemButton
                                onClick={() => navigate(`/group/${group.id}`)}
                                sx={{ pl: 1 }}
                            >
                                <FolderIcon />
                                <ListItemText sx={{ ml: 1 }}>{group.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={openGroups[group.id]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {events
                                    .filter(event => getOneLink(event.group)?.id === group.id)
                                    .map((event) => (
                                        <ListItem
                                            key={event.id}
                                            disablePadding
                                            style={styles.eventItem}
                                            sx={{ pl: 4 }}
                                            secondaryAction={
                                                <IconButton
                                                    onClick={() => handleClickDeleteEvent(event)}
                                                    edge="end"
                                                    aria-label="delete"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemButton onClick={() => navigate(`/event/${event.id}`)}>
                                                <ListItemText>{event.name}</ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Collapse>
                    </div>
                ))}

                {ungroupedEvents.map((event) => (
                    <ListItem
                        key={event.id}
                        disablePadding
                        style={styles.eventItem}
                        sx={{ pl: 1 }}
                        secondaryAction={
                            <IconButton
                                onClick={() => handleClickDeleteEvent(event)}
                                edge="end"
                                aria-label="delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemButton onClick={() => navigate(`/event/${event.id}`)}>
                            <ListItemText>{event.name}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Paper>
    </>;
}

const styles: Record<string, React.CSSProperties> = {
    eventsList: {},
    eventItem: {},
};