import { FieldArray } from "formik";
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from "@mui/material";
import EventSelector from "./EventSelector";

interface Props {
    eventIds: string[];
    isSubmitting: boolean;
    editMode: boolean;
}

export default function EventSelectorList(props: Props) {
    const { eventIds, isSubmitting, editMode } = props;

    return <FieldArray name="eventIds">
        {({ push, remove }) => (
            <>
                {eventIds.map((eventId, index) => (
                    <EventSelector
                        key={index}
                        index={index}
                        eventId={eventId}
                        editMode={editMode}
                        remove={remove}
                    />
                ))}

                {editMode && <Stack direction="row-reverse" key="stack">
                    <Button
                        color="success"
                        disabled={isSubmitting}
                        onClick={() => {
                            push(null);
                        }}
                        startIcon={<AddIcon />}
                    >
                        Add event
                    </Button>
                </Stack>}
            </>
        )}
    </FieldArray>
}