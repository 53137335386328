import { Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import Dropdown, { DropdownOption } from "./Dropdown";
import { db } from "src/instant";
import { EventFormValues } from "src/schema";
import { AppSchema } from "@zw-app/shared/instant.schema";
import { InstaQLResult } from "@instantdb/react";
import { useEffect, useMemo } from "react";

interface Props {
    index: number
}

type WasteCategory = InstaQLResult<AppSchema, { wasteCategories: {} }>['wasteCategories'][number];

export default function CustomMaterialCategoryDropdown(props: Props) {
    const { data, isLoading, error } = db.useQuery({
        wasteCategories: {
            $: { order: { order: 'asc' } }
        },
    });

    const { values, setFieldValue, isSubmitting } = useFormikContext<EventFormValues>();
    const { categoryId } = values.customMaterials[props.index];
    const fieldName = `customMaterials[${props.index}].categoryId`;

    const {
        categoryOptions,
        disableCategoryDropdown,
    } = useMemo(() => {
        const wasteCategories = data?.wasteCategories ?? [];

        const categoryOptions: DropdownOption<string>[] = wasteCategories.map(
            category => ({
                label: category.name,
                value: category.id,
            })
        );

        const categoryMap = new Map<string, WasteCategory>();
        for (let category of wasteCategories) {
            categoryMap.set(category.id, category)
        }

        // const currentCategory = categoryId ? categoryMap.get(categoryId) : undefined;

        let disableCategoryDropdown = false;

        if (isLoading || isSubmitting || categoryOptions.length === 0) {
            disableCategoryDropdown = true;
        }

        return {
            categoryOptions,
            disableCategoryDropdown,
        };
    }, [
        data,
        isLoading,
        isSubmitting,
    ]);

    // Make sure category is valid
    useEffect(() => {
        const categoryOptionIds = categoryOptions.map(opt => opt.value);

        if (!categoryOptionIds.includes(categoryId)) {
            if (categoryOptions.length > 0) {
                setFieldValue(fieldName, categoryOptions[0].value)
                    .catch(console.error);
            }
        }
    }, [fieldName, categoryId, categoryOptions, setFieldValue]);

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    return <Field
        name={fieldName}
        component={Dropdown}
        options={categoryOptions}
        // value={currentCategoryId || ""}
        label="Category"
        // handleChange={updateCategoryFromDropdown}
        disabled={disableCategoryDropdown}
        sx={{ width: "120px" }}
    />;
}