import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field } from 'formik';
import { EventMap } from "../schema";
import DeleteMapDialog from "./DeleteMapDialog";
import { useState } from "react";
import FormTextInput from "./FormTextInput";

interface Props {
    index: number;
    eventName: string;
    map: EventMap;
    deleteable: boolean;
    editMode: boolean;
}

export default function MapListSection(props: Props) {
    const { index, eventName, map, deleteable, editMode } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [mapToDelete, setMapToDelete] = useState<EventMap | undefined>(undefined);

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleClickDelete = (map: EventMap) => {
        setMapToDelete(map);
        setDialogOpen(true);
    };

    return <Box>
        <DeleteMapDialog
            eventName={eventName}
            map={mapToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
        />
        <Divider />
        <br />
        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Box sx={{ position: "relative" }}>
                {editMode ? <Stack direction="row">
                    <Field
                        name={`existingMaps[${index}].name`}
                        component={FormTextInput}
                        label="Map name"
                        sx={{ flexGrow: 1 }}
                    />
                    {deleteable && (
                        <IconButton
                            sx={{}}
                            onClick={() => handleClickDelete(map)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Stack> : (
                    <Typography variant="h6" sx={{ flex: 1, textAlign: "center" }}>
                        {map.name}
                    </Typography>
                )}
            </Box>
            <br />
            <img
                style={{ maxWidth: "200px", maxHeight: "200px", margin: "auto" }}
                alt="map bg"
                src={map.bgImageUrl}
            />
            <br />
        </Stack>
    </Box>;
}