import { createContext, useContext } from "react";
import { Event, EventGroup, EventMap, StationType, WasteMeasurementWithMaterial } from "./schema";

interface IMapContext {
    editMode: boolean
    toggleEditMode: () => void
    cleanupMode: boolean
    toggleCleanupMode: () => void
    currentStation: StationType | null
    setCurrentStation: (icon: StationType | null) => void
    eventId: string | undefined
    mapNumber: number
}

export interface IWasteDataContext {
    datasetId: string
    editMode: boolean
    toggleEditMode: () => void
    selectedRow: WasteMeasurementWithMaterial | null
    setSelectedRow: (id: WasteMeasurementWithMaterial | null) => void,
}


const defaultSetCurrentIcon = () => console.error("OOPS: setCurrentStation undefined");
export const MapContext = createContext<IMapContext>({
    editMode: false,
    toggleEditMode: () => { },
    cleanupMode: false,
    toggleCleanupMode: () => { },
    currentStation: null,
    setCurrentStation: defaultSetCurrentIcon,
    eventId: undefined,
    mapNumber: 0,
})

export const WasteDataContext = createContext<IWasteDataContext>({
    datasetId: "",
    editMode: false,
    toggleEditMode: () => { },
    selectedRow: null,
    setSelectedRow: () => { },
})

interface IEventsContext {
    events: Map<string, Event>,
    eventGroups: Map<string, EventGroup>,
}

interface IScrollContext {
    scrollToBottom: () => void,
}

export const EventsContext = createContext<IEventsContext>({
    events: new Map(),
    eventGroups: new Map(),
});

export const ScrollContext = createContext<IScrollContext>({
    scrollToBottom: () => { }
});

export const ViewOnlyContext = createContext<boolean>(false);

export const useEventGroup = (groupId?: string): EventGroup | undefined => {
    const { eventGroups } = useContext(EventsContext);
    if (groupId) {
        const eventGroup = eventGroups.get(groupId);
        return eventGroup;
    }
}

export const useEventGroupList = (): EventGroup[] => {
    const { eventGroups } = useContext(EventsContext);
    return Array.from(eventGroups, ([k, v]) => v);
}

export const useEvent = (eventId?: string | null): Event | undefined => {
    const { events } = useContext(EventsContext);
    if (eventId) {
        const event = events.get(eventId);
        return event;
    }
}

// Get a map from an event
export const useEventMap = (eventId: string | undefined, mapNumber: number): EventMap | undefined => {
    const event = useEvent(eventId);
    const maps = event?.maps || [];
    return maps[mapNumber];
}

export const useEventsList = (): Event[] => {
    const { events } = useContext(EventsContext);
    // convert map to array
    const eventsList = Array.from(events, ([k, v]) => v);
    return eventsList;
}

// Get the map of ids to events
export const useEventsMap = (): Map<string, Event> => {
    const { events } = useContext(EventsContext);
    return events;
}