import { EventMap } from "../schema";
import MapListSection from "./MapListSection";

interface Props {
    eventName: string;
    existingMaps: EventMap[];
    editMode: boolean;
}

export default function MapList(props: Props) {
    const { eventName, existingMaps, editMode } = props;

    return (
        <>
            {existingMaps.map((map, index) => (
                <MapListSection
                    key={map.id}
                    index={index}
                    eventName={eventName}
                    map={map}
                    // Don't let the last map be deleted
                    deleteable={existingMaps.length > 1}
                    editMode={editMode}
                />
            ))}
        </>
    );
}