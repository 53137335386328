import { Box, Divider, IconButton, Stack } from "@mui/material";
import { Field } from "formik";
import ImagePicker from './ImagePicker';
import CloseIcon from "@mui/icons-material/Close";
import { bgImageKey, mapNameKey } from "../upload";
import FormTextInput from "./FormTextInput";
import { CreateMap } from "src/schema";

interface Props {
    map: CreateMap
    index: number
    deleteable: boolean
    remove: (index: number) => void
}

export default function CreateMapFormSection(props: Props) {
    const { map, index, deleteable, remove } = props;
    return <Box key={map.id} >
        <Divider />
        <br />

        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "center" }}
            >
                <Field
                    name={mapNameKey(index)}
                    required={false}
                    component={FormTextInput}
                    label="Map name (optional)"
                />

                { // Don't allow the last map to be removed
                    deleteable && <IconButton
                        sx={{ width: "40px", height: "40px", margin: "auto" }}
                        onClick={() => remove(index)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            </Stack>
            <br />

            <Field
                name={bgImageKey(index)}
                component={ImagePicker}
                label="Image upload:"
            />
            <br />
        </Stack>
    </Box>
}