import { MuiFileInput } from 'mui-file-input'
import { FormGroup, Stack, Typography } from '@mui/material';
import { FieldProps } from 'formik';
import { useEffect, useState, useRef } from 'react';
import b64 from 'base64-js';

interface Props {
    label: string
}

export default function ImagePicker(props: FieldProps<File | null> & Props) {
    const { form, field } = props;
    const [imageView, setImageView] = useState<JSX.Element>(<></>);
    const meta = form.getFieldMeta(field.name);

    // Use ref for async operations to avoid stale closures
    const currentFileRef = useRef<File | null>(null);

    useEffect(() => {
        let isMounted = true;
        const file = field.value;

        const updatePreview = async (file: File) => {
            try {
                const buf = await file.arrayBuffer();
                if (!isMounted || file !== currentFileRef.current) return;

                const byteArray = new Uint8Array(buf);
                const encoded = b64.fromByteArray(byteArray);
                const dataUrl = `data:${file.type};base64,${encoded}`;
                setImageView(<img style={styles.image} alt="uploaded" src={dataUrl} />);
            } catch (error) {
                console.error('Error processing file:', error);
            }
        };

        currentFileRef.current = file;

        if (file) {
            updatePreview(file);
        } else {
            setImageView(<></>);
        }

        return () => {
            isMounted = false;
        };
    }, [field.value]);  // Only depends on Formik's value

    return <FormGroup>
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Typography width="150px">{props.label}</Typography>
                <MuiFileInput
                    value={field.value}
                    inputProps={{ accept: "image/*" }}
                    onChange={(newFile) => {
                        form.setFieldTouched(field.name, true);
                        form.setFieldValue(field.name, newFile);
                    }}
                />
            </Stack>
            <Stack direction="column" spacing={1}>
                {imageView}
            </Stack>
            {meta.touched && meta.error && (
                <Typography variant="caption" color="error">
                    {meta.error}
                </Typography>
            )}
        </Stack>
    </FormGroup>;
}

const styles: Record<string, React.CSSProperties> = {
    image: {
        maxWidth: "200px",
        maxHeight: "200px",
        margin: "auto",
    }
};