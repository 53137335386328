import { InstaQLEntity, InstaQLResult } from "@instantdb/react";
import * as Yup from "yup";
import { AppSchema } from "@zw-app/shared/instant.schema";
import convert from "convert-units";

const stationType = {};
const pins = { stationType };
const maps = { pins };
const measurements = {};
const category = {};
const customMaterials = { category };
const datasets = { measurements };
const group = {};
const events = { maps, datasets, customMaterials, group };

export type Event = InstaQLEntity<AppSchema, 'events', typeof events>;
export type EventGroup = InstaQLEntity<AppSchema, 'eventGroups', {events: {}}>;
export type EventMap = InstaQLEntity<AppSchema, 'maps', typeof maps>;
export type WasteDataset = InstaQLEntity<AppSchema, 'wasteDatasets', typeof datasets>;
export type MapPin = InstaQLEntity<AppSchema, 'mapPins', typeof pins>;
export type StationType = InstaQLEntity<AppSchema, 'stationTypes', typeof stationType>;
export type MeasurementUnit = InstaQLEntity<AppSchema, 'wasteMeasurements'>['units'];

const massUnits = convert().possibilities("mass");
const volumeUnits = convert().possibilities("volume");
const validUnits = [...massUnits, ...volumeUnits] as MeasurementUnit[];
export const dataInputValidationSchema = Yup.object({
    quantity: Yup.number()
        .typeError("Quantity must be a valid number")
        .required("Quantity is required"),
    units: Yup.string()
        .oneOf(validUnits, "Invalid unit")
        .required("Units are required"),
    categoryId: Yup.string()
        .required("Category is required"),
    materialId: Yup.string()
        .required("Category is required"),
});

export type DataInputValues = Yup.InferType<typeof dataInputValidationSchema>;

export interface CreateMap {
    id: string
    name?: string
    bgImage: File | null
}

const newMapSchema = Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
    bgImage: Yup.mixed<File>()
        .required("Map background image is required")
        // .nonNullable()
        .test("fileSize", "File size is too large", (value) => {
            return value && value.size <= 5 * 1024 * 1024; // 5MB limit
        })
        .test("fileType", "Unsupported file format", (value) => {
            return value && ["image/jpeg", "image/png"].includes(value.type);
        }),
})

const existingMapSchema = Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
    bgImageUrl: Yup.string().required("Map image URL is required")
})

const datasetSchema = Yup.object({
    id: Yup.string(),
    name: Yup.string(),
    order: Yup.number().required(),
})

export type DatasetSchema = Yup.InferType<typeof datasetSchema>;

const customMaterialSchema = Yup.object({
    id: Yup.string(),
    name: Yup.string().required().nonNullable(),
    categoryId: Yup.string().required(),
    order: Yup.number().required(),
})

export type CustomMaterialSchema = Yup.InferType<typeof customMaterialSchema>;

export const eventFormSchema = Yup.object({
    eventName: Yup.string().required("Event name is required"),
    datasets: Yup.array(datasetSchema).required(),
    existingMaps: Yup.array(existingMapSchema).required(),
    newMaps: Yup.array(newMapSchema).required()
}).test("numMaps", "At least one map is required", (value) => {
    return value.newMaps.length + value.existingMaps.length > 0;
});

// TODO: extract this from eventFormSchema Yup.object?
export interface EventFormValues {
    eventName: string;
    existingMaps: EventMap[];
    datasets: DatasetSchema[];
    customMaterials: CustomMaterialSchema[],
    newMaps: CreateMap[];
}

export const eventGroupFormSchema = Yup.object({
    groupName: Yup.string().required("Group name is required"),
    eventIds: Yup.array(
        Yup.string()
        .required("Please select an event")
    ).required(),
})

export interface EventForGroupValues {
    id: string | null;
    order: number;
}
// export type EventForGroupValues = Yup.InferType<typeof eventForGroupSchema>;

// export interface EventGroupFormValues {
//     groupName: string;
//     events: EventForGroup[];
// }
export type EventGroupFormValues = Yup.InferType<typeof eventGroupFormSchema>;

export type WasteMeasurementWithMaterial = InstaQLResult<AppSchema, {
    wasteMeasurements: {
        material: {
            category: {},
        },
    }
}>['wasteMeasurements'][number]