import { Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FieldProps } from "formik";

export interface DropdownOption<T> {
    label: string
    value: T
}

export const NEW_CUSTOM_OPTION: DropdownOption<string> = {
    label: "~ Custom",
    value: "new-custom",
}

interface Props<T> {
    standardOptions: DropdownOption<T>[],
    customOptions: DropdownOption<T>[],
    label: string,
    // value: T,
    // disabled?: boolean,
    // handleChange: (...args: any[]) => void,
}

export default function MaterialDropdown<T extends string | number>(props: FieldProps & Props<T>) {
    const {
        form,
        field,
        meta,
        standardOptions,
        customOptions,
        label,
        // disabled,
        // handleChange,
        ...otherProps
    } = props;

    return <FormControl sx={{ margin: "5px" }} >
        <InputLabel id={`${field.name}-label`}>{label}</InputLabel>
        <Select
            id={field.name}
            labelId={`${field.name}-label`}
            label={label}
            name={field.name}
            value={props.field.value}
            onChange={props.field.onChange}
            onBlur={props.field.onBlur}
            error={props.form.touched[field.name] && Boolean(props.form.errors[field.name])}
            {...otherProps}
        >
            {standardOptions.map(opt =>
                <MenuItem
                    key={opt.value}
                    value={opt.value}>
                    {opt.label}
                </MenuItem>
            )}
            <MenuItem
                key={NEW_CUSTOM_OPTION.value}
                value={NEW_CUSTOM_OPTION.value}
            >
                {NEW_CUSTOM_OPTION.label}
            </MenuItem>
            {customOptions.length > 0 && <Divider />}
            {customOptions.map(opt =>
                <MenuItem
                    key={opt.value}
                    value={opt.value}>
                    {opt.label}
                </MenuItem>
            )}


        </Select>
    </FormControl >
}