import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Field } from 'formik';
import { Link } from "react-router-dom";
import { useCallback } from "react";
import EventAutocomplete from "./EventAutocomplete";
import { useEvent } from "src/context";

interface Props {
    index: number;
    eventId: string | null;
    editMode: boolean;
    remove: (index: number) => void
}

export default function EventSelector(props: Props) {
    const { index, eventId, editMode, remove } = props;

    const event = useEvent(eventId);

    const handleClickRemove = useCallback(() => {
        remove(index);
    }, [index, remove])

    return <Box>
        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Box sx={{ position: "relative" }}>
                {editMode ? <Stack direction="row">
                    <Field
                        name={`eventIds[${index}]`}
                        component={EventAutocomplete}
                    />
                    <IconButton
                        onClick={handleClickRemove}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack> : (
                    <Link to={`/event/${eventId}`}>
                        <Typography sx={{ textAlign: "center" }}>
                            #{index + 1}: {event?.name}
                        </Typography>
                    </Link>
                )}
            </Box>
            <br />
        </Stack>
    </Box>;
}