import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { db } from "../instant";
import { AppSchema } from "@zw-app/shared/instant.schema";
import { TransactionChunk } from "@instantdb/admin";
import { EventGroup } from "../schema";
interface Props {
    eventGroup?: EventGroup,
    open: boolean,
    maxOrder: number,
    onClose: () => void,
}

export default function DeleteEventGroupDialog(props: Props) {
    const { user } = db.useAuth();

    if (props.eventGroup === null) {
        return <></>
    }

    const deleteAndClose = async () => {
        if (props.eventGroup) {
            const txs: TransactionChunk<AppSchema, 'events' | 'eventGroups'>[] = [];

            // re-index the events
            for (let i = 0; i < props.eventGroup.events.length; i++) {
                const event = props.eventGroup.events[i];
                const newOrder = props.maxOrder + i + 1;
                txs.push(db.tx.events[event.id].update({order: newOrder}));
            }

            // delete the group
            txs.push(db.tx.eventGroups[props.eventGroup.id].delete());

            await db.transact(txs);
            props.onClose();
        }
    }

    const eventGroupName = props.eventGroup?.name || "<UNDEFINED>"

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really delete this group?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The event group "{eventGroupName}" will be deleted.
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button disabled={!user} onClick={deleteAndClose} color="error">Delete</Button>
        </DialogActions>
    </Dialog>
}