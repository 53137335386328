import { Box, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Field } from 'formik';
import { DatasetSchema } from "../schema";
import DeleteDatasetDialog from "./DeleteDatasetDialog";
import { useCallback, useState } from "react";
import FormTextInput from "./FormTextInput";
import { db } from "src/instant";


interface Props {
    index: number;
    eventName: string;
    dataset: DatasetSchema;
    deleteable: boolean;
    editMode: boolean;
    remove: (index: number) => void
}

export default function DatasetListSection(props: Props) {
    const { index, eventName, dataset, deleteable, editMode, remove } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [datasetToDelete, setDatasetToDelete] = useState<DatasetSchema | undefined>(undefined);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const removeField = useCallback(() => {
        remove(index)
    }, [index, remove]);

    // whether to just remove the dataset
    // (because it's not in the database, 
    // so don't need to delete)
    const justRemove = (dataset.id === undefined);
    const handleClickRemove = useCallback(() => {
        remove(index);
    }, [index, remove])

    const handleClickDelete = useCallback(() => {
        setDatasetToDelete(dataset);
        setDialogOpen(true);
    }, [dataset, setDatasetToDelete, setDialogOpen]);

    const query = props.dataset?.id ? {
        wasteMeasurements: {
            $: {
                where: {
                    'dataset.id': props.dataset.id,
                }
            }
        }
    } : {};

    const { data, error } = db.useQuery(query)
    const numMeasurements = data?.wasteMeasurements?.length || 0;

    if (error) {
        console.error(error);
        return <></>;
    }

    return <Box>
        <DeleteDatasetDialog
            eventName={eventName}
            dataset={datasetToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
            numMeasurements={numMeasurements}
            removeField={removeField}
        />
        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Box sx={{ position: "relative" }}>
                {editMode ? <Stack direction="row">
                    <Field
                        name={`datasets[${index}].name`}
                        component={FormTextInput}
                        label="Dataset name"
                        sx={{ flexGrow: 1 }}
                    />
                    {deleteable && (justRemove ? (
                        <IconButton
                            sx={{}}
                            onClick={handleClickRemove}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            sx={{}}
                            onClick={handleClickDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    ))}
                </Stack> : (
                    <Typography sx={{ textAlign: "center" }}>
                        #{index + 1}: {dataset.name || "(no name)"} ({numMeasurements} measurements)
                    </Typography>
                )}
            </Box>
            <br />
        </Stack>
    </Box>;
}