import { Typography } from "@mui/material";
import { EventsContext } from "../context";
import { db } from "../instant";
import CacheUpdater from "./CacheUpdater"
import LoadingIndicator from "./LoadingIndicator";

interface Props {
    children: React.ReactNode;
}

export default function EventsProvider(props: Props) {
    const sortByOrderField = { $: { order: { order: 'asc' } } } as const;
    const { data, isLoading, error } = db.useQuery({
        events: {
            group: {},
            maps: {
                pins: {
                    stationType: {}
                },
                ...sortByOrderField
            },
            datasets: {
                ...sortByOrderField
            },
            customMaterials: {
                category: {},
                ...sortByOrderField
            },
            ...sortByOrderField
        },
        eventGroups: {
            events: {
                ...sortByOrderField
            },
            ...sortByOrderField
        },
    });

    if (isLoading) {
        return <LoadingIndicator message="Loading event data..." />;
    }

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    const eventsList = data?.events || [];
    const eventGroupsList = data?.eventGroups || [];
    const eventsMap = new Map();
    const eventGroupsMap = new Map();

    for (let event of eventsList) {
        eventsMap.set(event.id, event);
    }

    for (let eventGroup of eventGroupsList) {
        eventGroupsMap.set(eventGroup.id, eventGroup);
    }

    return <EventsContext.Provider value={{ events: eventsMap, eventGroups: eventGroupsMap }}>
        {props.children}
        <CacheUpdater />
    </EventsContext.Provider>
}