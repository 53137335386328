import { FieldArray } from "formik";
import { CustomMaterialSchema } from "../schema";
import CustomMaterialListSection from "./CustomMaterialListSection";
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from "@mui/material";
import { useCallback } from "react";

interface Props {
    eventName: string;
    customMaterials: CustomMaterialSchema[];
    isSubmitting: boolean;
    editMode: boolean;
}

export default function CustomMaterialList(props: Props) {
    const { eventName, customMaterials, isSubmitting, editMode } = props;

    const numCustomMaterials = customMaterials.length;
    const newCustomMaterial = useCallback(() => {
        return {
            order: numCustomMaterials,
            name: '',
            categoryId: '',
        };
    }, [numCustomMaterials]);

    return <FieldArray name="customMaterials">
        {({ push, remove }) => (
            <>
                {customMaterials.map((customMaterial, index) => (
                    <CustomMaterialListSection
                        key={index}
                        index={index}
                        eventName={eventName}
                        customMaterial={customMaterial}
                        editMode={editMode}
                        remove={remove}
                    />
                ))}

                {editMode && <Stack direction="row-reverse" key="stack">
                    <Button
                        color="success"
                        disabled={isSubmitting}
                        onClick={() => {
                            push(newCustomMaterial());
                        }}
                        startIcon={<AddIcon />}
                    >
                        Add custom material
                    </Button>
                </Stack>}
            </>
        )}
    </FieldArray>
}