import { Box, Pagination, Paper, SxProps } from "@mui/material";

interface Props {
    numPages: number
    currentPage: number
    setPage: (page: number) => void
    sx?: SxProps
}

export default function FloatingPagination(props: Props) {
    return <Box
        sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            paddingBottom: "20px",
            paddingTop: "20px",
            display: "flex",
            zIndex: 500,
            pointerEvents: "none",
            ...props.sx
        }}>
        <Paper
            elevation={2}
            sx={{
                borderRadius: 5,
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <Pagination
                sx={{
                    margin: 1,
                    pointerEvents: "auto",
                }}
                siblingCount={0}
                count={props.numPages}
                // page numbers start at 1.
                // Use +1 / -1 to adjust for JS zero-indexing.
                page={props.currentPage + 1}
                onChange={(_, page) => props.setPage(page - 1)}
            />
        </Paper>
    </Box>
}