import EventForm from "./EventForm";

interface Props {
    eventId: string
}

export default function EventInfo(props: Props) {
    const { eventId } = props;
    return <>
        <EventForm eventId={eventId} />
    </>
}