import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import DataInputForm from "./DataInputForm";
import DataViewTable from "./DataViewTable";
import { useCallback, useContext, useState } from "react";
import { WasteMeasurementWithMaterial } from "src/schema";
import WasteDataExportButton from "./WasteDataExportButton";
import EditDataButton from "./EditDataButton";
import { IWasteDataContext, useEvent, ViewOnlyContext, WasteDataContext } from "src/context";
import FloatingPagination from "./FloatingPagination";
import { EDIT_DATA_BUTTON_HEIGHT } from "src/components/EditDataButton";
import { EVENT_BOTTOM_NAV_HEIGHT } from "./EventBottomNavigation";

interface Props {
    eventId: string;
}

export default function EventData(props: Props) {
    const [selectedRow, setSelectedRow] = useState<WasteMeasurementWithMaterial | null>(null);
    const viewOnly = useContext(ViewOnlyContext);
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = useCallback(() => {
        setEditMode(!viewOnly && !editMode);
        setSelectedRow(null);
    }, [editMode, viewOnly, setEditMode, setSelectedRow]);
    const [datasetIndex, setDatasetIndex] = useState(0);

    const event = useEvent(props.eventId);

    const datasets = event?.datasets || [];
    const dataset = event?.datasets[datasetIndex];
    const datasetId = dataset?.id || "";

    const dataContext: IWasteDataContext = {
        datasetId,
        editMode,
        toggleEditMode,
        selectedRow,
        setSelectedRow,
    };

    return <WasteDataContext.Provider value={dataContext}>
        <Box
            id="event-data"
            sx={{ width: "100%", height: "100%" }}
        >
            {dataset?.name && <Typography variant="h5" sx={{ textAlign: "center" }}>
                Dataset: {dataset?.name}
            </Typography>}
            <Container
                maxWidth="md"
                id="data-view-table-container"
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: "20px",
                }}
            >
                <Paper
                    id="data-view-table-paper"
                    sx={{
                        width: "100%",
                        overflow: "auto",
                        justifyItems: "center",
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            width: "100%",
                            justifyContent: "space-around",
                        }}
                    >
                        <WasteDataExportButton eventId={props.eventId} />
                        {!viewOnly && <EditDataButton />}
                    </Stack>
                    <DataViewTable datasetId={datasetId} />
                </Paper >
                <Box sx={{ height: EDIT_DATA_BUTTON_HEIGHT + 8 }} />
                {!editMode && datasets.length > 1 && <FloatingPagination
                    numPages={datasets.length}
                    currentPage={datasetIndex}
                    setPage={setDatasetIndex}
                    sx={{
                        bottom: `${EVENT_BOTTOM_NAV_HEIGHT}px`
                        // position: "inherit",
                        // paddingBottom: "0px",
                    }}
                />}
            </Container>
            {editMode && <DataInputForm eventId={props.eventId} />}
        </Box>
    </WasteDataContext.Provider>
}