import { createBrowserRouter, redirect, useNavigate } from "react-router-dom"
import MapMaterialIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import DescriptionIcon from "@mui/icons-material/Description";
import BarChartIcon from "@mui/icons-material/BarChart";
import InfoIcon from "@mui/icons-material/Info";
import LoginScreen from "./screens/LoginScreen"
import { db } from "./instant";
import { CREATE_EVENT_GROUP_ROUTE, CREATE_EVENT_ROUTE, DEFAULT_ROUTE, EVENTS_LIST_ROUTE, LOGIN_ROUTE } from "./constants";
import ErrorScreen from "./screens/ErrorScreen";
import CreateEventScreen from "./screens/CreateEventScreen";
import EventsListScreen from "./screens/EventsListScreen";
import EventScreen from "./screens/EventScreen";
import { ComponentType, ReactNode } from "react";
import EventMapContainer from "./components/EventMapContainer";
import EventInventory from "./components/EventInventory";
import EventNotes from "./components/EventNotes";
import EventData from "./components/EventData";
import EventInfo from "./components/EventInfo";
import CreateEventGroupScreen from "./screens/CreateEventGroupScreen";
import EventGroupScreen from "./screens/EventGroupScreen";

export interface EventTabConfig {
    id: string;
    label: string;
    icon: ComponentType;
    component: ComponentType<{ eventId: string }>;
    privateOnly?: boolean;
}

export const eventTabsConfig: EventTabConfig[] = [
    {
        id: "map",
        label: "Map",
        icon: MapMaterialIcon,
        component: EventMapContainer,
    },
    {
        id: "inventory",
        label: "Inventory",
        icon: ListIcon,
        component: EventInventory,
    },
    {
        id: "notes",
        label: "Notes",
        icon: DescriptionIcon,
        component: EventNotes,
    },
    {
        id: "data",
        label: "Data",
        icon: BarChartIcon,
        component: EventData,
    },
    {
        id: "info",
        label: "Info",
        icon: InfoIcon,
        component: EventInfo,
        privateOnly: true,
    },
];
interface RouteProps {
    children: ReactNode,
}

interface RouteConfig {
    path: string;
    element?: ReactNode;
    private?: boolean;
    loader?: (...args: any[]) => any;
    errorElement?: ReactNode;
}

interface RouteMode {
    prefix: string;
    isPrivate: boolean;
    viewOnly: boolean;
}

function PrivateRoute({ children }: RouteProps) {
    const { isLoading, user, error } = db.useAuth()
    const navigate = useNavigate();

    // TODO: This flashes before the page loads, kind of annoying
    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Uh oh! {error.message}</div>
    }

    if (user) {
        return <>
            {children}
        </>;
    }

    // If not loading, errored, or logged in, redirect to the login screen.
    navigate(LOGIN_ROUTE, { replace: true });
    return <></>;
}

function privateRoute(children: ReactNode) {
    return <PrivateRoute>{children}</PrivateRoute>;
}

// Utility to create redirect loaders for event routes
const createEventRedirectLoader = (prefix: string) => {
    return (args: { params: { eventId: string } }) => {
        const { eventId } = args.params;
        return redirect(`${prefix}/${eventId}/map`);
    };
};

// Route modes (normal and viewOnly)
const routeModes: RouteMode[] = [
    { prefix: "/event", isPrivate: true, viewOnly: false },
    { prefix: "/viewevent", isPrivate: false, viewOnly: true }
];

// Generate routes
const generateRoutes = (): RouteConfig[] => {
    // Base routes
    const routes: RouteConfig[] = [
        {
            path: "/",
            loader: () => redirect(DEFAULT_ROUTE),
            errorElement: <ErrorScreen />
        },
        {
            path: LOGIN_ROUTE,
            element: <LoginScreen />
        },
        {
            path: CREATE_EVENT_ROUTE,
            element: <CreateEventScreen />,
            private: true
        },
        {
            path: CREATE_EVENT_GROUP_ROUTE,
            element: <CreateEventGroupScreen />,
            private: true
        },
        {
            path: EVENTS_LIST_ROUTE,
            element: <EventsListScreen />,
            private: true
        },
        {
            path: "/group/:groupId",
            element: <EventGroupScreen />,
            private: true
        }
    ];

    // Generate all event routes for both modes
    routeModes.forEach(({ prefix, isPrivate, viewOnly }) => {
        // Reuse the same redirect loader for both root and wildcard routes
        const redirectLoader = createEventRedirectLoader(prefix);

        // Add tab routes
        eventTabsConfig.forEach(({ id: tab, privateOnly }) => {
            // Skip view-only routes for tabs that should be private only
            if (viewOnly && privateOnly) return;

            routes.push({
                path: `${prefix}/:eventId/${tab}`,
                element: isPrivate
                    ? privateRoute(<EventScreen viewOnly={viewOnly} />)
                    : <EventScreen viewOnly={viewOnly} />,
                private: isPrivate
            });
        });

        // Add redirect routes - reusing the same loader function
        routes.push({
            path: `${prefix}/:eventId`,
            loader: redirectLoader
        });

        routes.push({
            path: `${prefix}/:eventId/*`,
            loader: redirectLoader
        });
    });

    return routes;
};

// Apply permissions and create the router
const createRouter = () => {
    const routes = generateRoutes();

    return createBrowserRouter(
        routes.map(route => ({
            path: route.path,
            element: route.private ? privateRoute(route.element) : route.element,
            loader: route.loader,
            errorElement: route.errorElement
        }))
    );
};

export const router = createRouter();
