import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useFormikContext } from "formik";

interface Props {
    open: boolean,
    onClose: () => void,
    discard: () => void,
}

export default function DiscardChangesDialog(props: Props) {
    const formik = useFormikContext();

    const discardAndClose = () => {
        props.discard();
        formik.resetForm();
        props.onClose();
    }

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really discard changes?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                There are unsaved changes.
                Would you like to discard them?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Keep Editing</Button>
            <Button onClick={discardAndClose} color="error">Discard</Button>
        </DialogActions>
    </Dialog>
}