import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { db } from '../instant';
import { id, TransactionChunk } from '@instantdb/core';
import { Button, Paper, Stack, Typography, Box, ToggleButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useState } from 'react';
import FormTextInput from './FormTextInput';
import DiscardChangesDialog from './DiscardChangesDialog';
import DirtyChecker from './DirtyChecker';
import { eventGroupFormSchema, EventGroupFormValues } from 'src/schema';
import { getOneLink } from 'src/utils';
import EventSelectorList from './EventSelectorList';
import { AppSchema } from '@zw-app/shared/instant.schema';

interface Props {
    groupId?: string;
    viewOnly?: boolean;
}

export default function EventGroupForm(props: Props) {
    const { groupId, viewOnly = false } = props;
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(!groupId);
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
    const isCreating = (groupId === undefined);

    const targetGroupId = groupId || id();

    const { data, isLoading, error } = db.useQuery({
        eventGroups: {
            $: {
                where: {
                    id: targetGroupId,
                },
            },
            events: {
                $: { order: { order: "asc" } },
            },
        },
        events: {},
    })

    const group = getOneLink(data?.eventGroups);
    const maxOrder = data?.events.reduce((max, event) => Math.max(max, event.order), 0) || 0;

    const [hasBeenEdited, setHasBeenEdited] = useState(false);

    const discard = useCallback(() => {
        setEditMode(false);
        setHasBeenEdited(false);
    }, [setEditMode, setHasBeenEdited]);

    const closeDiscardDialog = useCallback(() => {
        setDiscardDialogOpen(false);
    }, [setDiscardDialogOpen]);

    const toggleEditMode = useCallback(() => {
        if (editMode && hasBeenEdited) {
            setDiscardDialogOpen(true);
        } else {
            setEditMode(!editMode)
        }
    }, [editMode, hasBeenEdited, setEditMode, setDiscardDialogOpen]);

    if (isLoading) return <div>Authenticating...</div>;
    if (error) return <div>Error authenticating: {error.message}</div>;

    const initialValues: EventGroupFormValues = group ? {
        groupName: group.name,
        eventIds: group.events.map(event => event.id),
    } : {
        groupName: '',
        eventIds: [],
    };

    const canEdit = !isCreating && !viewOnly;

    const subtitle = isCreating ? "Create Group" : editMode ? "Edit Group" : "Group Info";

    return <>
        <Paper sx={{ position: "relative", minWidth: "300px", width: "100%", maxWidth: "400px", margin: "20px", padding: 2 }} elevation={4}>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                    {subtitle}
                </Typography>
                {canEdit && <ToggleButton
                    value="edit-mode"
                    selected={editMode}
                    onClick={toggleEditMode}
                >
                    <EditIcon />
                </ToggleButton>}
            </Box>
            <Formik
                initialValues={initialValues}
                // If events change in DB, update the form values
                // e.g. when deleting maps, or after saving changes
                enableReinitialize
                validationSchema={eventGroupFormSchema}
                onSubmit={async (values) => {
                    const txs: TransactionChunk<AppSchema, "events" |"eventGroups">[] = [
                        db.tx.eventGroups[targetGroupId].update({
                            name: values.groupName,
                        }).link({
                            events: values.eventIds,
                        })
                    ];

                    // Update event orders
                    // TODO: This will leave gaps in the unsorted event orders.
                    // Should be re-indexed when deleting groups or ungrouping events.
                    for (let i = 0; i < values.eventIds.length; i++) {
                        txs.push(
                            db.tx.events[values.eventIds[i]].update({
                                order: i
                            })
                        );
                    }

                    // Unlink events that were removed from the form
                    if (!isCreating) {
                        const removedEventIds = group?.events
                            .filter(oldEvent => !values.eventIds.includes(oldEvent.id))
                            .map(event => event.id) || [];

                        if (removedEventIds.length > 0) {
                            txs.push(db.tx.eventGroups[targetGroupId].unlink({
                                events: removedEventIds
                            }));
                        }

                        // re-index the events
                        for (let i = 0; i < removedEventIds.length; i++) {
                            const eventId = removedEventIds[i];
                            // TODO: This will leave gaps in the unsorted event orders.
                            const newOrder = maxOrder + i + 1;
                            txs.push(db.tx.events[eventId].update({order: newOrder}));
                        }
                    }

                    await db.transact(txs);

                    if (isCreating) {
                        navigate(`/group/${targetGroupId}`)
                    } else {
                        setEditMode(false);
                    }
                }}
            >
                {({ values, isSubmitting }) => {
                    return <>
                        <DiscardChangesDialog
                            discard={discard}
                            open={discardDialogOpen}
                            onClose={closeDiscardDialog}
                        />
                        <DirtyChecker dirty={hasBeenEdited} setDirty={setHasBeenEdited} />
                        <Form>
                            <Stack direction="column" spacing={1} sx={{ paddingTop: "10px" }}>
                                {editMode && (
                                    <Field
                                        autoFocus={!groupId}
                                        required
                                        name="groupName"
                                        component={FormTextInput}
                                        label="Group name"
                                        fullWidth
                                    />
                                )}

                                <br />
                                <Typography variant="h5" sx={{ textAlign: "center" }}>
                                    Events
                                </Typography>
                                <EventSelectorList
                                    eventIds={values.eventIds}
                                    isSubmitting={isSubmitting}
                                    editMode={editMode}
                                />

                                {editMode && <>
                                    <br />
                                    <Stack direction="row" sx={{ justifyContent: "center" }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            {isCreating ? 'Create Group' : 'Save All Changes'}
                                        </Button>
                                    </Stack>
                                </>}


                            </Stack>
                        </Form>
                    </>
                }}
            </Formik>
        </Paper>
    </>;
}