import { Checkbox, Stack, Typography } from "@mui/material";
import { MapPin } from "../schema";
import { db } from "../instant";
import { useContext } from "react";
import { ViewOnlyContext } from "../context";

interface Props {
    pin: MapPin
}

export default function CleanUpButton(props: Props) {
    const viewOnly = useContext(ViewOnlyContext);

    const isCleanedUp = props.pin.cleanedUp || false;
    const pinId = props.pin.id;

    const toggleChecked = (ev: React.MouseEvent<HTMLButtonElement>) => {
        // Prevent clicking checkbox from closing the popup
        ev.stopPropagation();

        // Not usable in view-only mode
        if (viewOnly) { return }

        db.transact(db.tx.mapPins[pinId].update({
            cleanedUp: !isCleanedUp
        })).catch(() => {
            console.log("error cleaning up station");
        });
    };

    return <Stack
        spacing={1}
        direction="row"
        sx={{
            alignItems: "center"
        }}
    >
        <Checkbox checked={isCleanedUp} onClick={toggleChecked} disabled={viewOnly} />
        <Typography>cleaned up</Typography>
    </Stack>
}