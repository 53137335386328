import { useMatch } from "react-router-dom"
import { Box } from "@mui/material";
import { ScrollContext, ViewOnlyContext } from "../context";
import EventMapHeader from "../components/EventMapHeader";
import { useRef } from "react";
import DefaultLayout from "src/layouts/DefaultLayout";
import EventBottomNavigation from "src/components/EventBottomNavigation";
import UnlessKeyboard from "src/components/UnlessKeyboard";
import { eventTabsConfig } from "src/routes";

interface Props {
    viewOnly?: boolean
}

export default function EventScreen(props: Props) {
    const viewOnly = props.viewOnly || false;
    const routePrefix = viewOnly ? '/viewevent' : '/event';
    const routeTemplate = `/${routePrefix}/:eventId/:activeTab`;

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        const container = scrollRef.current;
        if (!container) return;
        container.scrollTop = container.scrollHeight;
    };

    const routeMatch = useMatch(routeTemplate);
    if (!routeMatch) {
        return <p>ERROR: unknown route</p>
    }

    const { eventId, activeTab } = routeMatch.params;

    if (!eventId) {
        return <p>ERROR: event id</p>
    }

    const currentTab = eventTabsConfig.find(tab => tab.id === activeTab);
    const activeTabId = eventTabsConfig.findIndex(tab => tab.id === activeTab);

    if (!currentTab) {
        return <p>ERROR: Unknown tab {activeTab}</p>;
    }

    const TabComponent = currentTab.component;

    return <ViewOnlyContext.Provider value={viewOnly}>
        <DefaultLayout>
            <Box
                id="event-content"
                ref={scrollRef}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    alignItems: "center",
                }}
            >
                <ScrollContext.Provider value={{ scrollToBottom }} >
                    <EventMapHeader eventId={eventId} />
                    <Box
                        id="event-inner-content"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            width: "100%",
                            boxSizing: "border-box",
                            alignItems: "center",
                        }} >
                        <TabComponent eventId={eventId} />
                    </Box>
                </ScrollContext.Provider>
            </Box>
            <UnlessKeyboard>
                <EventBottomNavigation
                    activeTabId={activeTabId}
                    routePrefix={routePrefix}
                    eventId={eventId}
                />
            </UnlessKeyboard>
        </DefaultLayout>
    </ViewOnlyContext.Provider >
}