export const LOGIN_ROUTE = "/login";
export const CREATE_EVENT_ROUTE = "/create-event";
export const CREATE_EVENT_GROUP_ROUTE = "/create-group";
export const EVENTS_LIST_ROUTE = "/events";
// home screen
export const DEFAULT_ROUTE = EVENTS_LIST_ROUTE;

export const IMAGE_CACHE_NAME = "images";
export const IMAGE_UPLOAD_QUEUE_NAME = "zw-image-upload";
export const IMAGE_UPLPOAD_RETRY_EVENT_TYPE = "replayQueue";

export const BACKEND_BASE_URL = process.env.NODE_ENV === 'production' ?
    "https://cf-worker-zw-backend.shannon-665.workers.dev" :
    "https://cf-worker-zw-backend-dev.shannon-665.workers.dev";

// instantdb id of latest version record
export const LATEST_VERSION_ID = "c233b98f-f205-4bca-94d2-d6b7fefa7b8b"
