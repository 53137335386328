import { i } from "@instantdb/core";
const _schema = i.schema({
    entities: {
        // $files: i.entity({
        //   "content-disposition": i.string().indexed(),
        //   "content-type": i.string().indexed(),
        //   "key-version": i.number(),
        //   metadata: i.string(),
        //   path: i.string().indexed(),
        //   size: i.number().indexed(),
        //   status: i.string().indexed(),
        //   url: i.string(),
        // }),
        $users: i.entity({
            email: i.string().unique().indexed(),
        }),
        authorizedUsers: i.entity({
            email: i.string(),
        }),
        events: i.entity({
            bgImageUrl: i.string(),
            name: i.string(),
            notes: i.string(),
            order: i.number().indexed(),
        }),
        latestVersion: i.entity({
            git_commit: i.string(),
        }),
        mapPins: i.entity({
            cleanedUp: i.boolean(),
            notes: i.string(),
            x: i.number(),
            y: i.number(),
        }),
        maps: i.entity({
            bgImageUrl: i.string(),
            name: i.string(),
            order: i.number().indexed(),
        }),
        stationTypes: i.entity({
            black: i.number(),
            blue: i.number(),
            compostBucket: i.number(),
            description: i.string(),
            green: i.number(),
            iconName: i.string(),
            name: i.string(),
            order: i.number().unique().indexed(),
            slimJim: i.number(),
        }),
        wasteDatasets: i.entity({
            name: i.string(),
            order: i.number().indexed(),
        }),
        wasteMeasurements: i.entity({
            quantity: i.number(),
            units: i.string(),
            timestamp: i.date().indexed(),
        }),
        wasteCategories: i.entity({
            name: i.string(),
            order: i.number().unique().indexed(),
        }),
        wasteMaterials: i.entity({
            name: i.string(),
            order: i.number().indexed(),
            custom: i.boolean(),
        }),
        eventGroups: i.entity({
            name: i.string(),
            order: i.number().indexed(),
        }),
        reports: i.entity({
            createdAt: i.date().indexed(),
            materialBreakdown: i.boolean(),
            pdfURL: i.string(),
            jpgURL: i.string(),
            googleDriveId: i.string(),
        }),
        reportTemplates: i.entity({
            name: i.string(),
            googleDriveId: i.string(),
        }),
        charts: i.entity({
            imageURL: i.string(),
            kind: i.string(),
        }),
    },
    links: {
        eventsMaps: {
            forward: {
                on: "events",
                has: "many",
                label: "maps",
            },
            reverse: {
                on: "maps",
                has: "one",
                label: "event",
                onDelete: "cascade",
            },
        },
        mapPinsStationTypes: {
            forward: {
                on: "mapPins",
                has: "one",
                label: "stationType",
            },
            reverse: {
                on: "stationTypes",
                has: "many",
                label: "mapPins",
            },
        },
        mapsMapPins: {
            forward: {
                on: "maps",
                has: "many",
                label: "pins",
            },
            reverse: {
                on: "mapPins",
                has: "one",
                label: "map",
                onDelete: "cascade",
            },
        },
        wasteMaterialsWasteCategories: {
            forward: {
                on: "wasteMaterials",
                has: "one",
                label: "category",
            },
            reverse: {
                on: "wasteCategories",
                has: "many",
                label: "materials",
            },
        },
        wasteMaterialsEvents: {
            forward: {
                on: "wasteMaterials",
                has: "one",
                label: "forEvent",
                onDelete: "cascade",
            },
            reverse: {
                on: "events",
                has: "many",
                label: "customMaterials",
            },
        },
        wasteMeasurementsWasteMaterials: {
            forward: {
                on: "wasteMeasurements",
                has: "one",
                label: "material",
                onDelete: "cascade",
            },
            reverse: {
                on: "wasteMaterials",
                has: "many",
                label: "measurements",
            },
        },
        wasteMeasurementsWasteDatasets: {
            forward: {
                on: "wasteMeasurements",
                has: "one",
                label: "dataset",
                onDelete: "cascade",
            },
            reverse: {
                on: "wasteDatasets",
                has: "many",
                label: "measurements",
            },
        },
        wasteDatasetsEvents: {
            forward: {
                on: "wasteDatasets",
                has: "one",
                label: "event",
                onDelete: "cascade",
            },
            reverse: {
                on: "events",
                has: "many",
                label: "datasets",
            },
        },
        eventsEventGroups: {
            forward: {
                on: "events",
                has: "one",
                label: "group",
            },
            reverse: {
                on: "eventGroups",
                has: "many",
                label: "events",
            },
        },
        reportsEvents: {
            forward: {
                on: "reports",
                has: "one",
                label: "event",
            },
            reverse: {
                on: "events",
                has: "many",
                label: "reports",
            },
        },
        reportsWasteDatasets: {
            forward: {
                on: "reports",
                has: "many",
                label: "datasets",
            },
            reverse: {
                on: "wasteDatasets",
                has: "many",
                label: "reports",
            },
        },
        reportsWasteMaterials: {
            forward: {
                on: "reports",
                has: "many",
                label: "materials",
            },
            reverse: {
                on: "wasteMaterials",
                has: "many",
                label: "reports",
            },
        },
        reportsWasteCategories: {
            forward: {
                on: "reports",
                has: "many",
                label: "categories",
            },
            reverse: {
                on: "wasteCategories",
                has: "many",
                label: "reports",
            },
        },
        reportsReportTemplates: {
            forward: {
                on: "reports",
                has: "one",
                label: "template",
            },
            reverse: {
                on: "reportTemplates",
                has: "many",
                label: "reports",
            },
        },
        chartsReports: {
            forward: {
                on: "charts",
                has: "one",
                label: "report",
            },
            reverse: {
                on: "reports",
                has: "many",
                label: "charts",
            },
        },
    },
    // If you use presence, you can define a room schema here
    // https://www.instantdb.com/docs/presence-and-topics#typesafety
    rooms: {},
});
const schema = _schema;
export default schema;
