import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import EventMapInner from "./EventMapInner";
import { fetchImage } from "../utils";
import { MapContext, useEvent } from "../context";

interface Props {
    mapNumber: number
}

export default function EventMap(props: Props) {
    const [backgroundImage] = useState(new Image());
    const { eventId } = useContext(MapContext);
    const event = useEvent(eventId);
    const [innerMap, setInnerMap] = useState<any>(null);
    const boxRef = useRef<HTMLDivElement>();

    const map = event?.maps[props.mapNumber];
    const imageUrl = map?.bgImageUrl;

    // Load the map background image
    useEffect(() => {
        if (imageUrl) {
            // Fetch the image
            fetchImage(imageUrl).then((objectUrl) => {
                // Render image on the screen
                backgroundImage.src = objectUrl;
            }).catch(console.error);
        }
    }, [backgroundImage, imageUrl])

    // Once the background image is loaded, render the map
    useEffect(() => {
        backgroundImage.onload = (ev) => {
            // Get the map background image
            const img = ev.target as HTMLImageElement;
            // Get the size of the available map area
            const view = boxRef.current;

            if (view) {
                setInnerMap(<EventMapInner
                    imgUrl={img.src}
                    imgWidth={img.width}
                    imgHeight={img.height}
                    viewWidth={view.clientWidth}
                    viewHeight={view.clientHeight}
                />);
            }
        }
    }, [backgroundImage]);

    return <Box ref={boxRef} sx={{ width: "100%", height: "100%" }}>
        {innerMap}
    </Box>
}