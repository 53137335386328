import Button from '@mui/material/Button';
import Papa from 'papaparse';
import { useContext } from 'react';
import { WasteDataContext } from 'src/context';
import { db } from 'src/instant';
import { getOneLink } from 'src/utils';

interface Props {
    eventId: string
}

export default function WasteDataExportButton(props: Props) {
    const { datasetId } = useContext(WasteDataContext);
    const handleExport = async () => {
        // Fetch measurements with related data
        const { data } = await db.queryOnce({
            wasteMeasurements: {
                $: {
                    where: {
                        'dataset.id': datasetId,
                    },
                    order: {
                        timestamp: 'asc'
                    },
                },
                material: {
                    category: {}
                }
            }
        });

        // Get event name (fallback to id)
        const { data: { events, wasteDatasets } } = await db.queryOnce({
            events: {
                $: {
                    where: {
                        id: props.eventId
                    }
                },
            },
            wasteDatasets: {
                $: {
                    where: {
                        id: datasetId,
                    }
                }
            }
        });
        const eventName = getOneLink(events)?.name || props.eventId;
        const datasetName = getOneLink(wasteDatasets)?.name || datasetId;

        // Group measurements by category
        const categories: Record<string, Array<{
            quantity: number;
            type?: string;
        }>> = {
            Landfill: [],
            Compost: [],
            Recycling: []
        };

        for (let measurement of data.wasteMeasurements) {
            const material = getOneLink(measurement?.material);
            const category = getOneLink(material?.category);
            console.log("?", measurement);
            if (material === undefined || category === undefined) {
                console.log("NOPE");
                continue;
            }
            console.log(category.name);
            if (categories[category.name]) {
                categories[category.name].push({
                    quantity: measurement.quantity,
                    type: category.name === 'Landfill' ? undefined : material.name,
                });
            }
        }

        // Generate CSV rows
        const csvData = [
            ['Landfill', 'Compost', 'Type', 'Recycling', 'Type of Mat.']
        ];

        const maxLength = Math.max(
            ...Object.values(categories).map(arr => arr.length)
        );

        for (let i = 0; i < maxLength; i++) {
            const row = [
        /* Landfill */ categories.Landfill[i]?.quantity.toFixed(1) || '',
        /* Compost */ categories.Compost[i]?.quantity.toFixed(1) || '',
        /* Type */ categories.Compost[i]?.type || '',
        /* Recycling */ categories.Recycling[i]?.quantity.toFixed(1) || '',
        /* Type of Mat. */ categories.Recycling[i]?.type || ''
            ];
            csvData.push(row);
        }

        // Create and download CSV
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `waste_data_${eventName}_${datasetName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button variant="contained" onClick={handleExport}>
            Export CSV
        </Button>
    );
};