import { FieldArray } from "formik";
import CreateMapFormSection from "./CreateMapFormSection";
import { Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { newEmptyMap } from "../upload";
import { CreateMap } from "src/schema";

interface Props {
    newMaps: CreateMap[]
    isSubmitting: boolean
    numOtherMaps?: number
}

export default function AddNewMaps(props: Props) {
    const { newMaps, isSubmitting } = props;
    const numOtherMaps = props.numOtherMaps || 0;
    return <FieldArray name="newMaps">
        {({ push, remove }) => (
            <>
                {newMaps.map((map, index) => (
                    <CreateMapFormSection
                        key={map.id}
                        map={map}
                        index={index}
                        deleteable={newMaps.length + numOtherMaps > 1}
                        remove={remove}
                    />
                ))}

                <Stack direction="row-reverse">
                    <Button
                        color="success"
                        disabled={isSubmitting}
                        onClick={() => push(newEmptyMap())}
                        startIcon={<AddIcon />}
                    >
                        Add map
                    </Button>
                </Stack>
            </>
        )}
    </FieldArray>
}