import CheckIcon from '@mui/icons-material/Check';
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem, MenuList, ListItemIcon, ListItemText, Snackbar, Paper } from "@mui/material";
import { useContext, useState } from "react";
import { MapContext, ViewOnlyContext } from "../context";
import ShareIcon from "@mui/icons-material/Share";

const ITEM_HEIGHT = 48;

export default function MapOptionsMenu() {
    const ctx = useContext(MapContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const viewOnly = useContext(ViewOnlyContext);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openSnackbar = () => setSnackbarOpen(true);
    const closeSnackbar = () => setSnackbarOpen(false);

    const onShare = async () => {
        const url =
            new URL(
                `/viewevent/${ctx.eventId}`,
                window.location.origin
            );
        await window.navigator.clipboard.writeText(url.toString());
        console.log('copied url:', url.toString());
        openSnackbar();
        handleClose();
    }

    return (
        <Paper
            elevation={2}
            sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
                zIndex: "1001",
                backgroundColor: "white",
                borderRadius: "5px",
            }}
        >
            <IconButton
                aria-label="options"
                id="options-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="options-menu"
                MenuListProps={{
                    'aria-labelledby': 'options-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25ch',
                    },
                }}
            >
                <MenuList>
                    {!viewOnly &&
                        <MenuItem onClick={() => {
                            ctx.toggleEditMode();
                            handleClose();
                        }}>
                            <ListItemIcon>
                                {ctx.editMode ? <CheckIcon /> : <></>}
                            </ListItemIcon>
                            <ListItemText>
                                Edit stations
                            </ListItemText>
                        </MenuItem>
                    }

                    <MenuItem onClick={() => {
                        ctx.toggleCleanupMode();
                        handleClose();
                    }}>
                        <ListItemIcon>
                            {ctx.cleanupMode ? <CheckIcon /> : <></>}
                        </ListItemIcon>
                        <ListItemText>
                            Clean-up mode
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={onShare}>
                        <ListItemIcon>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Share view-only
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={closeSnackbar}
                message="Copied link to clipboard!"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            />
        </Paper>
    );
}