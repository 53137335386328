import { Box, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Field } from 'formik';
import { CustomMaterialSchema } from "../schema";
import DeleteCustomMaterialDialog from "./DeleteCustomMaterialDialog";
import { useCallback, useState } from "react";
import FormTextInput from "./FormTextInput";
import { db } from "src/instant";
import CustomMaterialCategoryDropdown from "./CustomMaterialCategoryDropdown";
import { getOneLink } from "src/utils";


interface Props {
    index: number;
    eventName: string;
    customMaterial: CustomMaterialSchema;
    editMode: boolean;
    remove: (index: number) => void
}

export default function CustomMaterialListSection(props: Props) {
    const { index, eventName, customMaterial, editMode, remove } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [materialToDelete, setMaterialToDelete] = useState<CustomMaterialSchema | undefined>(undefined);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const removeField = useCallback(() => {
        remove(index)
    }, [index, remove]);

    // whether to just remove the material
    // (because it's not in the database, 
    // so don't need to delete)
    const justRemove = (customMaterial.id === undefined);
    const handleClickRemove = useCallback(() => {
        remove(index);
    }, [index, remove])

    const handleClickDelete = useCallback(() => {
        setMaterialToDelete(customMaterial);
        setDialogOpen(true);
    }, [customMaterial, setMaterialToDelete, setDialogOpen]);

    const query = props.customMaterial?.id ? {
        wasteMeasurements: {
            $: {
                where: {
                    'material.id': props.customMaterial.id,
                }
            }
        },
        wasteCategories: {
            $: {
                where: {
                    id: props.customMaterial.categoryId,
                }
            }
        },
    } : {};

    const { data, error } = db.useQuery(query)
    const numMeasurements = data?.wasteMeasurements?.length || 0;

    const category = getOneLink(data?.wasteCategories);

    if (error) {
        console.error(error);
        return <></>;
    }

    return <Box>
        <DeleteCustomMaterialDialog
            eventName={eventName}
            customMaterial={materialToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
            numMeasurements={numMeasurements}
            removeField={removeField}
        />
        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Box sx={{ position: "relative" }}>
                {editMode ? <Stack
                    direction="row"
                    sx={{ alignItems: "center" }}
                >
                    <Field
                        name={`customMaterials[${index}].name`}
                        component={FormTextInput}
                        required={true}
                        label="Material name"
                        sx={{ flexGrow: 1 }}
                    />
                    <CustomMaterialCategoryDropdown index={index} />
                    {justRemove ? (
                        <IconButton
                            sx={{}}
                            onClick={handleClickRemove}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            sx={{}}
                            onClick={handleClickDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Stack> : (
                    <Typography sx={{ textAlign: "center" }}>
                        #{index + 1}: {customMaterial.name} ({category?.name}, {numMeasurements} measurements)
                    </Typography>
                )}
            </Box>
            <br />
        </Stack>
    </Box>;
}