import React, { useState } from 'react'
import { db } from '../instant'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_ROUTE } from '../constants'

function Login() {
  const [sentEmail, setSentEmail] = useState('')
  return (
    <div style={authStyles.container}>
      {!sentEmail ? (
        <Email setSentEmail={setSentEmail} />
      ) : (
        <MagicCode sentEmail={sentEmail} />
      )}
    </div>
  )
}

interface EmailProps {
  setSentEmail: (email: string) => void
}

function Email({ setSentEmail }: EmailProps) {
  const [email, setEmail] = useState('')
  const { isLoading, error, data } = db.useQuery({ authorizedUsers: {} });

  // TODO: clean this up
  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    throw error;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email) {
      return;
    }

    // Make sure the user is authorized!
    // TODO: Should this really be done on the backend?
    // with this approach, the list of authorizedUsers is public...
    const authorizedEmails = data.authorizedUsers.map(user => user.email);
    if (!authorizedEmails.includes(email)) {
      alert("You aren't authorized!");
      setSentEmail('')
      return;
    }

    setSentEmail(email)
    db.auth.sendMagicCode({ email }).catch((err) => {
      alert('Uh oh :' + err.body?.message)
      setSentEmail('')
    })
  }

  return (
    <form onSubmit={handleSubmit} style={authStyles.form}>
      <h2 style={{ color: '#333', marginBottom: '20px' }}>Let's log you in!</h2>
      <div>
        <input
          style={authStyles.input}
          placeholder="Enter your email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <button type="submit" style={authStyles.button}>
          Send Code
        </button>
      </div>
    </form>
  )
}

interface MagicCodeProps {
  sentEmail: string
}

function MagicCode({ sentEmail }: MagicCodeProps) {
  const [code, setCode] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    db.auth.signInWithMagicCode({ email: sentEmail, code }).catch((err) => {
      alert('Uh oh :' + err.body?.message)
      setCode('')
    })
  }

  return (
    <form onSubmit={handleSubmit} style={authStyles.form}>
      <h2 style={{ color: '#333', marginBottom: '20px' }}>
        Okay, we sent you an email! What was the code?
      </h2>
      <div>
        <input
          style={authStyles.input}
          type="text"
          placeholder="123456..."
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>
      <button type="submit" style={authStyles.button}>
        Verify
      </button>
    </form>
  )
}

const authStyles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  input: {
    padding: '10px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    width: '300px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}

export default function LoginScreen() {
  const { isLoading, user, error } = db.useAuth()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Uh oh! {error.message}</div>
  }
  if (user) {
    const nextRoute = searchParams.get('redirect') || DEFAULT_ROUTE;
    navigate(nextRoute, { replace: true });
  }
  return <Login />
}
